<template>
	<safeguard-table-with-filter
		report-link="reports/grm"
		:table-headers="[
			{ key: 'project_id' },
			{ key: 'project_name_arabic' },
			{ key: 'project_name_english' },
			{ key: 'name_of_the_implementing_partner', label: 'اسم الشريك المنفذ' },
			{ key: 'telephone' },
			{ key: 'complaint_method' },
			{ key: 'date_of_complaint' },
			{ key: 'type_of_compliance' },
			{ key: 'donor' },
			{ key: 'governorate' },
			{ key: 'directorate' },
			{ key: 'uzlah', label: 'العزلة' },
			{ key: 'address' },
			{ key: 'details' },
			{ key: 'gender' },
			{ key: 'complaint_decided_date', label: 'تاريخ البت في الشكوى' },
			{ key: 'solution', label: 'كيف تم حـــل الشكوى' },
			{ key: 'status_of_the_complaint' },
		]"
	/>
</template>

<script>
import SafeguardTableWithFilter from '../components/SafeguardTableWithFilter.vue';

export default {
	name: 'LocationVisits',
	components: { SafeguardTableWithFilter },
};
</script>
